<template>
  <div class="ai">
    <div class="top">
      <img
        class="background"
        src="../assets/img/head-ai.png"
      />
    </div>
    <div class="desc">
      <div class="txt">
        {{descTxt[0]}}
      </div>
      <div class="txt">
        {{descTxt[1]}}
      </div>
      <div class="desc-img">
        <img
          src="../assets/img/desc-img-left.png"
          alt=""
        >
        <img
          src="../assets/img/desc-img-right.png"
          alt=""
        >
      </div>
    </div>
    <div class="pic-show">
      <div class="row1">
        <img
          src="../assets/img/pic-show-row1.png"
          alt=""
        >
      </div>
      <div class="row2">
        <div>
          <img
            src="../assets/img/pic-show-row2-left.png"
            alt=""
          >
        </div>
        <div class="row2-right">
          <div>
            <img
              src="../assets/img/pic-show-row2-right-1.png"
              alt=""
            >
          </div>
          <div>
            <img
              src="../assets/img/pic-show-row2-right-2.png"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AI',
  data () {
    return {
      descTxt: ["We are an AI/ML startup shifting from a face-swapping app to a social platform for personalized content creation \
      and unlimited self-expression of the future the next big thing in personalized content creation the synonym of digital self-expression.",
        "We are getting huge, and we are open for talented and daring people: talk to us, cooperate with us, become one of us"],
    }
  },
  components: {

  }
}
</script>

<style>
.ai > .desc {
  width: 80%;
  margin: 40px auto;
}

.row2 {
  display: flex;
}

.pic-show {
  width: 100%;
  overflow: hidden;
}

.ai .desc {
  font-size: 27px;
}

.desc > .txt {
  margin-bottom: 40px;
  line-height: 40px;
}

.desc > .desc-img {
  display: flex;
  justify-content: space-between;
}
</style>